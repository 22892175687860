import React from 'react'
import {
  ResourcesTable,
  ResourcesTableFilters,
} from 'src/next/components/management/Resources'
import {
  TableFilterContainer,
  TableFilterContextProvider,
} from 'src/next/components/TableFilter'

export const ResourcesPage = () => {
  return (
    <TableFilterContextProvider localStorageId="admin-resources-filters">
      <TableFilterContainer>
        <ResourcesTableFilters />
        <ResourcesTable />
      </TableFilterContainer>
    </TableFilterContextProvider>
  )
}
