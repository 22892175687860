import { InlineNotification, SkeletonText } from '@carbon/react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { useManagementOrgs } from 'src/api'
import {
  Tabs,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
} from 'src/next/components/Tabs'

import Loading from 'src/next/components/Loading'
import { OrgNamespacesTable } from 'src/next/components/management/Organizations/ExpandedRow/Namespaces/OrgNamespacesTable'
import { OrgOverviewTab } from 'src/next/components/management/Organizations/ExpandedRow/Overview/OrgOverviewTab'
import { OrgResourcesTable } from 'src/next/components/management/Organizations/ExpandedRow/Resources/OrgResourcesTable'
import { PageTitle } from 'src/next/components/ui'
import Typography from 'src/next/components/Typography'
import { css } from 'styled-components'

export const OrgDetailPage = () => {
  const { orgId } = useParams<{ orgId: string }>()
  const { t } = useTranslation()

  const { data, isLoading, isError } = useManagementOrgs({
    filter: {
      orgUuid: orgId,
    },
  })

  const org = data?.pages[0]?.orgs?.find(org => org.uuid === orgId)

  return (
    <>
      <Loading active={isLoading} />
      {isLoading || (
        <div
          css={css`
            min-height: 100vh;
          `}
        >
          {isError || !org ? (
            <InlineNotification
              title={t(
                'Organizations.FetchFailed_one',
                "Failed to retrieve organization's data",
              )}
              kind="error"
            />
          ) : (
            <div>
              <PageTitle
                title={
                  isLoading ? <SkeletonText width="200px" /> : org?.name || ''
                }
              >
                <Typography variant="productive-heading-01" as="h2">
                  {t('Organizations.organizations')}
                </Typography>
              </PageTitle>
              <Tabs>
                <TabList aria-label="Organization detail page tabs">
                  <Tab id={`organization-${orgId}-panel-overview`}>
                    {t('Common.Overview')}
                  </Tab>
                  <Tab id={`organization-${orgId}-panel-namespaces`}>
                    {t('Organizations.Namespaces.Title')}
                  </Tab>
                  <Tab id={`organization-${orgId}-panel-resources`}>
                    {t('Organizations.Resources', 'Resources')}
                  </Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <OrgOverviewTab org={org} />
                  </TabPanel>
                  <TabPanel>
                    <OrgNamespacesTable id={orgId!} />
                  </TabPanel>
                  <TabPanel>
                    <OrgResourcesTable id={orgId!} />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </div>
          )}
        </div>
      )}
    </>
  )
}
