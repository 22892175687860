import React from 'react'
import { UsersTable } from 'src/next/components/management'
import { SubscriptionBanner } from 'src/next/components/SubscriptionBanner'
import { TableFilterContextProvider } from 'src/next/components/TableFilter'

export const UsersPage = () => {
  return (
    <>
      <SubscriptionBanner type="USER" />
      <TableFilterContextProvider>
        <UsersTable />
      </TableFilterContextProvider>
    </>
  )
}
