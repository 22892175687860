import React, { Dispatch, SetStateAction, useState } from 'react'
import { SelectItem, TimePicker, TimePickerSelect } from '@carbon/react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

export type MeridiemType = 'AM' | 'PM'

export interface TimeInputProps {
  id: string
  setTime: Dispatch<SetStateAction<string>>
  value: string
  label?: string
  meridiem: MeridiemType
  setMeridiem: Dispatch<SetStateAction<MeridiemType>>
}

const StyledTimePicker = styled(TimePicker)`
  position: relative;
  display: flex;
  width: 100%;
  cursor: pointer;

  .cds--time-picker {
    &__input {
      width: 100%;
    }
    &__select {
      width: fit-content;
    }
  }

  &::-webkit-calendar-picker-indicator {
    display: none;
  }

  .cds--select-input {
    display: flex;
    justify-content: end;
  }

  .cds--select__arrow {
    display: none;
  }

  &::-webkit-datetime-edit-ampm-field {
    display: none;
  }
`

export const timeFormatRegex = /^([1][0-2]|[0]?[1-9]):([0-5][0-9])$/

export const TimeInput = ({
  setTime,
  setMeridiem,
  meridiem,
  value,
  id,
  label,
}: TimeInputProps) => {
  const [valid, setValid] = useState(true)
  const { t } = useTranslation()

  const handleTimeChange = (time: string) => {
    if (timeFormatRegex.test(time)) {
      setValid(true)
      setTime(time)
    } else {
      setValid(false)
    }
  }

  return (
    <StyledTimePicker
      id={id}
      placeholder="hh:mm"
      labelText={label || t('DateTimeRangePicker.Time')}
      value={value}
      invalid={!valid}
      pattern="/^([1][0-2]|[0]?[1-9]):[0-5][0-9]$/"
      onChange={e => handleTimeChange(e.target.value)}
    >
      <TimePickerSelect
        id="time-picker-select-1"
        labelText={label || t('DateTimeRangePicker.Time')}
        value={meridiem}
        onChange={e => setMeridiem(e.target.value as MeridiemType)}
      >
        <SelectItem value="AM" text="AM" />
        <SelectItem value="PM" text="PM" />
      </TimePickerSelect>
    </StyledTimePicker>
  )
}
