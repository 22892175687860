/* eslint-disable react/display-name */
import React, { lazy, memo } from 'react'
import * as Sentry from '@sentry/react'
import { HeaderContainer } from '@carbon/react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { GetUserResponse } from '@cloudnatix-types/usercontroller'
import { useOrgs } from 'src/api/queries/useOrgs'
import { useUserSelf } from 'src/api/queries/useUsers'
import { AppShell } from 'src/next/components/AppShell'
import Loading from 'src/next/components/Loading'
import { CLUIProvider } from 'src/next/contexts'
import { ClustersPage } from 'src/next/pages/clusters/ClustersPage'
import { DashboardPage } from 'src/next/pages/dashboard/DashboardPage'
import { InsightsPage } from 'src/next/pages/insights/InsightsPage'
import { AdministrationPage, OrgDetailPage } from 'src/next/pages/management'
import Workloads from 'src/next/pages/workloads'
import { hasAPIDocAccess, hasDeploymentDashboardAccess } from './util'

const Workload = lazy(() => import('src/next/pages/workload'))
const ClusterDetailPage = lazy(
  () => import('src/next/pages/clusters/ClusterDetailPage'),
)

const ComputeInstanceDetailPage = lazy(() =>
  import('src/next/pages/vm-workload').then(
    ({ ComputeInstanceDetailPage }) => ({
      default: ComputeInstanceDetailPage,
    }),
  ),
)

const DeploymentDashboard = lazy(() =>
  import('src/next/pages/deployment-dashboard').then(
    ({ DeploymentDashboardPage }) => ({ default: DeploymentDashboardPage }),
  ),
)

const ApiDoc = lazy(() =>
  import('src/next/pages/api-doc/ApiDoc').then(({ ApiDoc }) => ({
    default: ApiDoc,
  })),
)

const KubernetesResourcesPage = lazy(() =>
  import('src/next/pages/kubernetes-resources').then(
    ({ KubernetesResourcesPage }) => ({
      default: KubernetesResourcesPage,
    }),
  ),
)

const KubernetesResourcePage = lazy(() =>
  import('src/next/pages/kubernetes-resources').then(
    ({ KubernetesResourcePage }) => ({
      default: KubernetesResourcePage,
    }),
  ),
)

const AppShellAndRoutes = () => {
  const { data: user } = useUserSelf()

  return (
    <AppShell>
      <Routes>
        <Route index element={<DashboardPage />} />
        <Route path="dashboard" element={<DashboardPage />} />
        <Route path="clusters" element={<ClustersPage />} />
        <Route path="clusters/:clusterId" element={<ClusterDetailPage />} />
        <Route
          path="kubernetes-resources"
          element={<KubernetesResourcesPage />}
        />
        <Route
          path="kubernetes-resources/:clusterUUID/:kind"
          element={<KubernetesResourcesPage />}
        />
        <Route
          path="kubernetes-resources/:clusterUUID/:kind/:workloadUID"
          element={<KubernetesResourcePage />}
        />
        <Route path="workloads" element={<Workloads />} />
        <Route path="workload/:workloadId" element={<Workload />} />
        <Route
          path="vm-workload/:instanceId"
          element={<ComputeInstanceDetailPage />}
        />

        <Route path="insights" element={<InsightsPage />} />

        <Route path="administration" element={<AdministrationPage />} />

        <Route path="administration/:orgId" element={<OrgDetailPage />} />

        {hasAPIDocAccess(user?.user) && (
          <Route path="apidoc" element={<ApiDoc />} />
        )}

        {hasDeploymentDashboardAccess(user?.user) ? (
          <Route
            path="deployment-dashboard"
            element={<DeploymentDashboard />}
          />
        ) : null}

        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </AppShell>
  )
}

export const AppContainer = memo(() => {
  const { isLoading: isLoadingUser, isError: isErrorUser } = useUserSelf({
    onSuccess: (data: GetUserResponse) => {
      if (data.user?.tenant?.name) {
        Sentry.setUser({ tenantId: data.user?.tenant?.name })
      }
    },
  })

  const { isLoading: isLoadingOrgs, isError: isErrorOrgs } = useOrgs()

  if (isLoadingUser || isLoadingOrgs) {
    return <Loading withOverlay={false} centered />
  }

  if (isErrorOrgs || isErrorUser) {
    // trigger global error page
    throw new Error(
      "We're having trouble getting info about your account right now.",
    )
  }

  return (
    <CLUIProvider>
      <HeaderContainer render={AppShellAndRoutes} />
    </CLUIProvider>
  )
})
