import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useManagementResources } from 'src/api'
import DataTable, {
  DataTableNestedPagination,
  usePagination,
} from 'src/next/components/DataTable'
import { InlineNotification } from 'src/next/components/InlineNotification'
import { useFormatRows } from 'src/next/components/management/Resources/ResourcesTable'

interface Props {
  id: string
}

export const OrgResourcesTable = ({ id }: Props) => {
  const { t } = useTranslation()
  const headers = useHeaders()

  const pagination = usePagination('org-resources-table-page-size')
  const { resetPage, setPage, ...dataTablePaginationProps } = pagination
  const query = useManagementResources({
    filter: {
      orgUuid: id,
    },
    orderBy: 'name asc',
    pageSize: pagination.pageSize,
  })

  const currentPageData = query.data?.pages?.[pagination.page - 1] || {}

  const resources = useMemo(
    () => currentPageData.resources || [],
    [currentPageData.resources],
  )

  const formattedRows = useFormatRows(resources)

  if (query.isError) {
    return (
      <InlineNotification
        title={t('Organizations.ResourcesTable.FetchFailed')}
        kind="error"
      />
    )
  }

  return (
    <>
      <DataTable
        storageKey="org-resources-table"
        headers={headers}
        rows={formattedRows}
        isLoading={query.isFetching}
        pageSize={pagination.pageSize}
        size="sm"
      />
      {!query.isFetching && !formattedRows?.length ? (
        <InlineNotification title={t('Organizations.ResourcesTable.NoData')} />
      ) : (
        <DataTableNestedPagination
          {...dataTablePaginationProps}
          query={query}
        />
      )}
    </>
  )
}

const useHeaders = () => {
  const { t } = useTranslation()

  return useMemo(
    () => [
      { key: 'name', header: t('Organizations.DataTable.Name') },
      { key: 'typeText', header: t('Organizations.Type') },
      { key: 'spend', header: t('Common.Spend') },
      { key: 'region', header: t('Common.Region') },
      { key: 'labels', header: t('Organizations.Labels') },
      { key: 'capacity', header: t('Common.Capacity') },
    ],
    [t],
  )
}
