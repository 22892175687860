import React, { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { TimeIntervals } from 'src/next/types'

export interface TimeIntervalProps {
  setTimeInterval: Dispatch<SetStateAction<TimeIntervals>>
  timeInterval: TimeIntervals

  removeIntervals?: TimeIntervals[]
}

export type ItemsTimeInterval = {
  id: TimeIntervals
  label: string
}

const StyledDropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledTimeRangeHeading = styled.h5`
  width: 100%;
  margin-bottom: 1rem;
`

const StyledTimeItem = styled.div<{ $active: boolean }>`
  padding: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  cursor: pointer;
  width: 100%;
  &:hover {
    background: var(--cds-background-hover);
  }

  ${({ $active }) => $active && 'background: var(--cds-background-hover);'}
`

const StyledColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

// durationMap is a map from time interval IDs to the duration of the interval
// in minutes.
export const durationMap = {
  minutes: 30,
  // 2 hours
  hours: 60 * 2,
  // 2 days
  days: 60 * 24 * 2,
  // 2 weeks
  weeks: 60 * 24 * 7 * 2,
  // 2 months
  months: ((60 * 24 * 365) / 12) * 2,
}

export const useTimeIntervals = () => {
  const { t } = useTranslation()
  const times: ItemsTimeInterval[] = [
    { id: 'minutes', label: t('DateTimeRangePicker.Last30Minutes') },
    { id: 'hours', label: t('DateTimeRangePicker.Last2Hours') },
    { id: 'days', label: t('DateTimeRangePicker.Last2Days') },
    { id: 'weeks', label: t('DateTimeRangePicker.Last2Weeks') },
    { id: 'months', label: t('DateTimeRangePicker.Last2Months') },
    { id: 'custom', label: t('DateTimeRangePicker.CustomRange') },
  ]

  return { times }
}

export const TimeInterval = ({
  setTimeInterval,
  timeInterval,
  removeIntervals,
}: TimeIntervalProps) => {
  const { t } = useTranslation()

  const { times: items } = useTimeIntervals()

  const times = items.filter(({ id }) => {
    return !removeIntervals?.includes(id)
  })

  return (
    <StyledDropdownContainer>
      <StyledTimeRangeHeading>
        {t('DateTimeRangePicker.TimeRange')}
      </StyledTimeRangeHeading>
      <StyledColumnContainer>
        {times.map(time => {
          return (
            <StyledTimeItem
              onClick={() => setTimeInterval(time.id)}
              $active={timeInterval === time.id}
              key={time.id}
            >
              {time.label}
            </StyledTimeItem>
          )
        })}
      </StyledColumnContainer>
    </StyledDropdownContainer>
  )
}
