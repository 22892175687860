import React, { useEffect, useRef, useState } from 'react'
import { ArrowLeft, Calendar } from '@carbon/react/icons'
import * as Popover from '@radix-ui/react-popover'
import { Button } from '@carbon/react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import {
  DateTimePicker,
  DateTimePickerSharedInputProps,
  maxDate as defaultMaxDate,
} from '../DateTimePicker'
import { TimeInterval, TimeIntervalProps } from '../TimeInterval'

export interface DatePickerProps
  extends DateTimePickerSharedInputProps,
    TimeIntervalProps {
  setDates: (date: Date[]) => void
  withTimeInput?: boolean
  dates: Date[]
}

export const ClearDateButton = styled.button`
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  color: var(--cds-text-primary);
  cursor: pointer;
  padding: var(--cds-spacing-04);

  &:hover {
    background: var(--cds-background-hover);
  }
`

const StyledPopoverTrigger = styled(Popover.Trigger)`
  display: flex;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: var(--cds-spacing-04);
  &:hover {
    background: var(--cds-background-hover);
  }
`

const StyledCalendarIcon = styled(Calendar)`
  color: var(--cds-icon-primary);
  height: 100%;
  &.active {
    color: var(--cds-button-interactive);
  }
`

const StyledDatePickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const StyledGoBack = styled.div`
  display: flex;
  align-items: center;
  gap: var(--cds-spacing-01);
  cursor: pointer;
  width: max-content;
  margin-bottom: 5px;

  &:hover {
    text-decoration: underline;
  }
`

const StyledCustomDateHeading = styled.h5`
  width: 100%;
  margin-bottom: 1rem;
`

const StyledButtonContainer = styled.div`
  margin-top: 24px;
`

const StyledContent = styled(Popover.Content)`
  padding: 1rem;
  display: flex;
  background-color: var(--cds-layer-01);
  margin-right: 32px;
  gap: var(--cds-spacing-05);
`

export const DatePickerMenu = ({
  id,
  minDate,
  maxDate = defaultMaxDate,
  datePickerType = 'single',
  withTimeInput,
  size = 'md',
  timeInterval,
  setTimeInterval,
  dates: dateTimes,
  setDates: setDateTimes,
  removeIntervals,
}: DatePickerProps) => {
  const { t } = useTranslation()
  const calendarRef = useRef<any>(null)
  const containerRef = useRef<any>(null)
  const [isCustomDate, setIsCustomDate] = useState(false)
  const [selectedDateTimes, setSelectedDateTimes] = useState<Date[]>(dateTimes)
  const [openMenu, setOpenMenu] = useState(false)

  useEffect(() => {
    if (dateTimes.length === 0) {
      setIsCustomDate(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openMenu, dateTimes])

  useEffect(() => {
    setSelectedDateTimes(dateTimes)
  }, [dateTimes])

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
      `}
    >
      <Popover.Root open={openMenu}>
        <StyledPopoverTrigger
          aria-label="calendar-icon-button"
          onClick={() => setOpenMenu(!openMenu)}
        >
          <StyledCalendarIcon className={`${openMenu && 'active'}`} />
        </StyledPopoverTrigger>

        <Popover.PopoverPortal>
          <StyledContent
            ref={containerRef}
            style={{ boxShadow: '0 2px 12px var(--cds-shadow)' }}
            onOpenAutoFocus={e => e.preventDefault()}
            onInteractOutside={e => {
              e.preventDefault()
              if (
                containerRef &&
                containerRef.current &&
                !containerRef.current.contains(e.target)
              ) {
                if (
                  (calendarRef && !calendarRef.current) ||
                  (calendarRef &&
                    calendarRef.current &&
                    !calendarRef.current.calendar.calendarContainer.contains(
                      e.target,
                    ))
                ) {
                  setOpenMenu(false)
                }
              }
            }}
          >
            {!isCustomDate && (
              <TimeInterval
                timeInterval={timeInterval}
                removeIntervals={removeIntervals}
                setTimeInterval={val => {
                  if (val === 'custom') {
                    setIsCustomDate(true)
                  } else {
                    setTimeInterval(val)
                    setDateTimes([])
                    setOpenMenu(false)
                  }
                }}
              />
            )}

            {isCustomDate && (
              <StyledDatePickerContainer>
                <StyledGoBack onClick={() => setIsCustomDate(false)}>
                  <ArrowLeft />
                  {t('DateTimeRangePicker.BackBtn')}
                </StyledGoBack>
                <StyledCustomDateHeading>
                  {t('DateTimeRangePicker.CustomRange')}
                </StyledCustomDateHeading>
                <DateTimePicker
                  ref={calendarRef}
                  id={id}
                  maxDate={maxDate}
                  minDate={minDate}
                  datePickerType={datePickerType}
                  size={size}
                  value={selectedDateTimes}
                  setValue={setSelectedDateTimes}
                  withTimeInput={withTimeInput}
                />
                <StyledButtonContainer>
                  <Button
                    onClick={() => {
                      setOpenMenu(false)
                    }}
                    style={{ width: '50%' }}
                    kind="secondary"
                  >
                    {t('DateTimeRangePicker.CancelBtn')}
                  </Button>
                  <Button
                    onClick={() => {
                      setTimeInterval('custom')
                      setDateTimes(selectedDateTimes)
                      setOpenMenu(false)
                    }}
                    style={{ width: '50%' }}
                    kind="primary"
                  >
                    {t('DateTimeRangePicker.ApplyBtn')}
                  </Button>
                </StyledButtonContainer>
              </StyledDatePickerContainer>
            )}
          </StyledContent>
        </Popover.PopoverPortal>
      </Popover.Root>
      {dateTimes.length !== 0 ? (
        <ClearDateButton
          onClick={() => {
            setDateTimes([])
            setTimeInterval('days')
          }}
        >
          {t('DateTimeRangePicker.ClearDate')}
        </ClearDateButton>
      ) : null}
    </div>
  )
}
